<template>
  <section>
    <vertical-tabs
      :tabs="tabs"
      @change="updateCurrentTab"
      :activeTab="currentTab"
    >
      <div class="tabs-content vertical">
        <div class="tabs-panel is-active">
          <keep-alive>
            <component v-bind:is="currentTab" :databank="databank" />
          </keep-alive>
        </div>
      </div>
    </vertical-tabs>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/eventBus';
import { CREATE_DATA_BANK, CREATE_TEMPLATE, REQUEST_UPLOAD } from '@/eventTypes';
import addDataBank from '@/graphql/mutations/dataBank/addDataBank.gql';
import createTemplate from '@/graphql/mutations/dataBank/createTemplate.gql';
import errorHandler from '@/utils/error.handler';

export default {
  props: {
    campaign: {
      required: true,
    },
  },
  components: {
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    FilesTab: () => import('@/components/settings/dataBank/FilesTab.vue'),
    ImageTab: () => import('@/components/settings/dataBank/ImageTab.vue'),
    LocationTab: () => import('@/components/settings/dataBank/LocationTab.vue'),
    TemplatesTab: () => import('@/components/settings/dataBank/TemplatesTab/index.vue'),
    TextTab: () => import('@/components/settings/dataBank/TextTab.vue'),
    VideoTab: () => import('@/components/settings/dataBank/VideoTab.vue'),
    VerticalTabs: () => import('@/components/VerticalTabs.vue'),
  },
  data() {
    return {
      currentTab: 'text-tab',
      type: 'text',
    };
  },
  created() {
    EventBus.$on(CREATE_DATA_BANK, (data) => {
      this.createData(data);
    });
    EventBus.$on(CREATE_TEMPLATE, (data) => {
      this.createTemplate(data);
    });
  },
  destroyed() {
    EventBus.$off(CREATE_TEMPLATE);
    EventBus.$off(CREATE_DATA_BANK);
  },
  mounted() {
    this.load();
    this.$emit('changed', false);
  },
  watch: {
    campaign(newValue, oldValue) {
      if ((newValue.id !== oldValue.id && this.$route.name === 'campaigns-settings')
        || (newValue.id === oldValue.id && newValue.company)) {
        this.load();
      }
    },
  },
  computed: {
    ...mapGetters({
      databank: 'databank/getDataBank',
    }),
    tabs() {
      return [
        {
          icon: 'text-subject-icon',
          title: this.$t('campaigns.data-bank.tab.text'),
          selected: true,
          type: 'text',
          component: 'text-tab',
        },
        {
          icon: 'image-icon',
          title: this.$t('campaigns.data-bank.tab.images'),
          selected: false,
          type: 'image',
          component: 'image-tab',
        },
        {
          icon: 'file-icon',
          title: this.$t('campaigns.data-bank.tab.files'),
          selected: false,
          type: 'document',
          component: 'files-tab',
        },
        {
          icon: 'video-icon',
          title: this.$t('campaigns.data-bank.tab.video'),
          selected: false,
          type: 'video',
          component: 'video-tab',
        },
        {
          icon: 'map-marker-icon',
          title: this.$t('campaigns.data-bank.tab.location'),
          selected: false,
          type: 'location',
          component: 'location-tab',
        },
        {
          icon: 'file-icon',
          title: 'Templates',
          selected: false,
          type: 'template',
          component: 'templates-tab',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchDataBank: 'databank/fetchDataBank',
      fetchTemplates: 'databank/fetchTemplates',
      addData: 'databank/addDataBank',
      addTemplate: 'databank/addTemplate',
    }),
    updateCurrentTab(tab) {
      this.currentTab = tab.component;
      this.type = tab.type;
      this.load();
    },
    async load() {
      this.fetchDataBank({
        id: this.campaign.id,
        type: this.type,
      });
      this.fetchTemplates(this.campaign.id);
    },
    async createData(data) {
      try {
        const result = await this.$apollo.mutate({
          mutation: addDataBank,
          variables: {
            campaign: this.campaign.id,
            ...data,
          },
        });
        this.addData(result.data.addDataBank);
      } catch (err) {
        errorHandler.logErrors(err);
        this.$toasted.global.error(this.$t('common.error-saving'));
      } finally {
        EventBus.$emit(REQUEST_UPLOAD);
      }
    },
    async createTemplate(data) {
      try {
        const result = await this.$apollo.mutate({
          mutation: createTemplate,
          variables: {
            campaign_id: this.campaign.id,
            ...data,
          },
        });
        EventBus.$emit('close-template-modal', true);
        this.addTemplate(result.data.createTemplate);
      } catch (err) {
        EventBus.$emit('close-template-modal', false);
        if (err.message.includes('duplicate key')) {
          this.$toasted.global.error(this.$t('campaigns.data-bank.templates.duplicated-error'));
        } else if (err.message.includes('baseUrl')) {
          this.$toasted.global.error(this.$t('campaigns.data-bank.templates.campaign-official-error'));
        } else {
          errorHandler.logErrors(err);
          this.$toasted.global.error(err.graphQLErrors[0].message);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/_vertical-tabs";
</style>
